import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomAccordion = ({
  title,
  children,
  expanded,
  onChange,
  disabled = false,
  ...props
}) => {
  return (
    <Accordion
      defaultExpanded={expanded}
      onChange={onChange}
      disabled={disabled}
      sx={{
        marginTop: 2,
        marginBottom: 2,
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      }}
      {...props}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: 'primary.default',
          '&.Mui-expanded': {
            minHeight: 48,
          },
        }}
      >
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: 'background.paper',
          padding: 2,
        }}
      >
        <Box>{children}</Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;