import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, } from '@mui/material';
import useAuth from '../services/auth';
import { jwtDecode } from 'jwt-decode';
import SupervisedUser from '../components/SupervisedUsers';
import Evaluation from '../components/Evaluation';
import useFactorService from '../services/factor';
import { getEvaluationTypeName } from '../services/constants';

const PerformanceEvaluation = () => {
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [isSuperVisor, setIsSuperVisor] = useState(false);
  const [token, setToken] = useState({});
  const [employeeRatings, setEmployeeRatings] = useState({});
  const [managerRatings, setManagerRatings] = useState({});
  const [factors, setFactors] = useState(null);
  const [evaluationType, setEvaluationType] = useState('');
  const { getToken } = useAuth();

  const handleEmployeeRatingsChange = (newRatings) => setEmployeeRatings({ ...employeeRatings, ...newRatings });
  const handleManagerRatingsChange = (newRatings) => setManagerRatings({ ...managerRatings, ...newRatings });

  // services
  const { getFactorsByRole } = useFactorService();

  const fetchFactors = async () => {
    const factors = await getFactorsByRole();
    setFactors(factors);
  };

  useEffect(() => {
    const token = getToken();
    let decodedToken = null;
    if (!!token) decodedToken = jwtDecode(token);

    if (!decodedToken) return;

    setToken(decodedToken)
    setSelectedEmployee(decodedToken.id);
    fetchFactors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedEmployee) return;

    setIsSuperVisor(selectedEmployee !== token.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployee]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      bgcolor: 'background.default',
      color: 'text.primary'
    }}>
      <Container maxWidth="lg" sx={{ flexGrow: 1, py: 4 }}>
        <SupervisedUser
          supervisorId={token.id}
          selectedEmployee={selectedEmployee}
          handleEmployeeChange={(e) => setSelectedEmployee(e.target.value)}
        />

        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <Typography variant="h4" mb={'2rem'} color="primary">
            Evaluación de habilidades y desempeño:
            <Typography variant="h4" color="white">
              {getEvaluationTypeName(evaluationType)}
            </Typography>
          </Typography>

        </Box>

        <Evaluation
          selectedEmployee={selectedEmployee}
          evaluatorId={token.id}
          isSuperVisor={isSuperVisor}
          employeeRatings={employeeRatings}
          managerRatings={managerRatings}
          onEmployeeRatingsChange={handleEmployeeRatingsChange}
          onManagerRatingsChange={handleManagerRatingsChange}
          setEmployeeRatings={setEmployeeRatings}
          setManagerRatings={setManagerRatings}
          factors={factors}
          evaluationType={evaluationType}
          setEvaluationType={setEvaluationType}
        />
      </Container>
    </Box>
  );
};

export default PerformanceEvaluation;