import React, { useState } from 'react';
import { Box, Typography, Alert, Container } from '@mui/material';
import { CustomInput } from '../components/CustomInput';
import { CustomButton } from '../components/CustomButton';
import { CustomLink } from '../components/CustomLink';
import backgroundVideo from '../assets/background-video.mp4';
import useAuth from '../services/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const LoginPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { login } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await axios.post(`${apiUrl}/login`, JSON.stringify(formData), {
        headers: {
          'Content-Type': 'application/json',
        },
      });



      if (response.status === 200) {
        const { token } = await response.data;
        login(token)
      } else {
        setError('Credenciales inválidas');
      }
    } catch (err) {
      if (err.status === 403) {
        navigate(`/email-password-reset/${err.response.data.token}`);
      }
      setError('Error al conectar con el servidor');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <video
        autoPlay
        loop
        muted
        controls="false"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      >
        <source src={backgroundVideo} type="video/mp4" />
        Tu navegador no soporta el tag de video.
      </video>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            maxWidth: 400,
            p: 4,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            Iniciar Sesión
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <CustomInput
            label="Correo o Documento"
            name="identifier"
            value={formData.identifier}
            onChange={(e) => setFormData({ ...formData, identifier: e.target.value })}
            disabled={isLoading}
            fullWidth
            margin="normal"
          />

          <CustomInput
            label="Contraseña"
            type="password"
            name="password"
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            disabled={isLoading}
            fullWidth
            margin="normal"
          />

          <CustomButton
            type="submit"
            fullWidth
            isLoading={isLoading}
            disabled={!formData.identifier || !formData.password}
            sx={{ mt: 3, mb: 2 }}
            text={isLoading ? 'Iniciando Sesión...' : 'Iniciar Sesión'}
          />

          <Box sx={{
            display: 'flex',
            justifyContent: 'END',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 1,
            alignItems: 'center'
          }}>
            <CustomLink to="/forgot-password">
              ¿Olvidó su contraseña?
            </CustomLink>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LoginPage;