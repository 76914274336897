import React, { useState, useCallback, useEffect } from 'react';
import {
  Select,
  MenuItem,
  Autocomplete,
  FormControl,
  InputLabel,
  Paper,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Container,
  IconButton,
  TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useActivityService from '../services/activity';
import userService from '../services/user';
import useSchedulePerformanceService from '../services/schedulePerformance';
import useAuth from '../services/auth';
import { jwtDecode } from 'jwt-decode';
import { CustomButton } from '../components/CustomButton';
import { useSnackbar } from '../components/CustomSnackBar';


const SchedulePerformance = () => {
  const [activities, setActivities] = useState([]);
  const [subActivities, setSubActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState('');
  const [selectedSubActivity, setSelectedSubActivity] = useState('');
  const [activityPairs, setActivityPairs] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [actualUserId, setActualUserId] = useState([]);
  const [users, setUsers] = useState([]);
  const { getToken } = useAuth();
  const showSnackbar = useSnackbar(); const { getUserByRolesLocation } = userService();
  const { getActivities } = useActivityService();
  const { createSchedulePerformance } = useSchedulePerformanceService();

  const handleAddPair = () => {
    if (selectedActivity && selectedSubActivity) {

      setActivityPairs([...activityPairs, { selectedActivity, selectedSubActivity }]);
    }

    setSelectedActivity('');
    setSelectedSubActivity('');
  };

  const handleRemovePair = (index) => {
    setActivityPairs(activityPairs.filter((_, i) => i !== index));
  };


  const handleGetActivityName = (id) => {
    if (!id) return '';

    const activity = activities.find((x) => x.id === id);
    return activity?.name;
  };

  const handleGetSubActivityName = (aid, id) => {
    if (!id) return '';
    const temActivity = activities.find((x) => x.id === aid);

    const subActivity = temActivity.sub_activities.find((x) => x.id === id);
    return subActivity.name;
  };

  const handleGetUserName = (id) => {
    if (!id) return '';

    const user = users.find((x) => x.id === id);
    return `${user.first_name} ${user.last_name}`;
  };

  const handleRemoveUser = (index) => {
    setSelectedUsers(selectedUsers.filter((_, i) => i !== index));
  };

  const handleSelectedAutocompleteChange = (event, value) => {
    if (selectedUsers.find((x) => x === value.id)) return;

    const id = value.map((x) => x.id);
    setSelectedUsers([...selectedUsers, ...id]);
  };

  const handleActivityChange = useCallback((event) => {
    setSelectedActivity(event.target.value);
    const selected = activities.find((x) => x.id === event.target.value);

    setSubActivities(selected?.sub_activities || []);
    setSelectedSubActivity('');
  }, [activities]);

  const handleSave = async () => {
    const response = await createSchedulePerformance({ selectedUsers, activityPairs });

    if (response.ok) {
      showSnackbar(response.message, 5000, 'success');

      if (users.findIndex((x) => x.id === actualUserId))
        setSelectedUsers([actualUserId]);

      setActivityPairs([]);
    } else {
      showSnackbar(response.message, 5000, 'error');
    }
  };

  useEffect(() => {
    if (selectedSubActivity === '') return;

    handleAddPair();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubActivity]);


  useEffect(() => {
    const fetchActivities = async () => {
      const activitiesData = await getActivities();
      setActivities(activitiesData);
    };

    const fetchUsers = async () => {
      const token = getToken();
      const decodedToken = jwtDecode(token);
      const users = await getUserByRolesLocation([42, 51, 65, 68, 73, 11], [8, 9]);
      const actualUser = users.find((x) => x.id === decodedToken.id);

      setActualUserId(actualUser?.id);
      setSelectedUsers([actualUser?.id]);
      setUsers(users);
    };

    fetchActivities();
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      bgcolor: 'background.default',
      color: 'text.primary'
    }}>
      <Container maxWidth="xl" sx={{ flexGrow: 1, py: 4 }}>
        <Typography variant="h4" marginBottom={2} color="primary">
          Rendimiento programado
        </Typography>
        <Box>

          {(selectedUsers.length > 0 || activityPairs.length > 0) && (
            <Box sx={{ mt: 4, p: 2 }}>
              <Paper elevation={3} sx={{ maxWidth: '100%', mx: 'auto', mb: 4, p: 2 }}>
                <Typography variant="h6" color="primary">
                  Usuarios
                </Typography>
                <Box>
                  <Autocomplete
                    options={users}
                    autoHighlight={true}
                    multiple={true}
                    blurOnSelect={false}
                    value={[]}
                    getOptionDisabled={(option) => option.id === actualUserId || selectedUsers.includes(option.id)}
                    clearOnBlur={true}
                    selectOnFocus={true}
                    sx={{ width: '100%', mb: 4, mt: 3 }}
                    getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                    getOptionKey={(option) => option.id}
                    onChange={handleSelectedAutocompleteChange}
                    renderInput={(params) => <TextField {...params} label="Usuarios" />}
                  />
                </Box>
                <Typography variant="h6" marginBottom={0} color="primary">
                  Usuarios Seleccionados
                </Typography>
                <List>
                  {selectedUsers?.map((userId, index) => (
                    <ListItem key={userId} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <ListItemText
                        primary={handleGetUserName(userId)}
                      />
                      {
                        actualUserId !== userId &&
                        <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveUser(index)}>
                          <DeleteIcon />
                        </IconButton>
                      }
                    </ListItem>
                  ))}
                </List>
              </Paper>

              <Paper elevation={3} sx={{ maxWidth: '100%', mx: 'auto', mb: 4, p: 2 }}>
                <Typography variant="h6" marginBottom={0} color="primary">
                  Actividades
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="activity-label">Actividad</InputLabel>
                    <Select
                      label="Actividad"
                      labelId="activity-label"
                      value={selectedActivity}
                      onChange={handleActivityChange}
                    >
                      {activities.map((activity) => (
                        <MenuItem key={activity.id} value={activity.id}>
                          {activity.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth margin="normal" disabled={!selectedActivity}>
                    <InputLabel id="sub-activity-label">Subactividad</InputLabel>
                    <Select
                      label="Subactividad"
                      labelId="sub-activity-label"
                      value={selectedSubActivity}
                      onChange={(e) => setSelectedSubActivity(e.target.value)}
                    >
                      {subActivities.map((subActivity) => (
                        <MenuItem key={subActivity.id} value={subActivity.id}>
                          {subActivity.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Typography variant="h6" marginBottom={0} color="primary">
                  Actividades y Subactividades Seleccionadas
                </Typography>
                <List>
                  {activityPairs.map((pair, index) => (
                    <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <ListItemText
                        primary={handleGetActivityName(pair.selectedActivity)}
                        secondary={handleGetSubActivityName(pair.selectedActivity, pair.selectedSubActivity)}
                      />
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemovePair(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </Paper>

              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: '2rem' }}>
                <CustomButton
                  type="button"
                  isLoading={false}
                  sx={{ mt: 3, mb: 2 }}
                  disabled={selectedUsers.length === 0 || activityPairs.length === 0}
                  onClick={() => handleSave()}
                  text='Guardar'
                />
              </Box>
            </Box>
          )}
        </Box>
      </Container >

    </Box >
  );
};

export default SchedulePerformance;