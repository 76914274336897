import React from 'react';
import PrivateRoute from './PrivateRoute';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Dashboard from '../pages/Dashboard';
import FollowUp from '../pages/FollowUp';
import SchedulePerformance from '../pages/SchedulePerformance';
import Home from '../pages/Home';

const RoutesApp = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route exact path="/password-reset/:token" element={<ResetPassword />} />
      <Route exact path="/email-password-reset/:token" element={<ResetPassword isEmailChange={true} />} />
      <Route exact path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
      <Route path="/evaluaciones" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
      <Route path="/evaluaciones/seguimiento" element={<PrivateRoute><FollowUp/></PrivateRoute>} />
      <Route path="/evaluaciones/seguimiento/:userId" element={<PrivateRoute><FollowUp/></PrivateRoute>} />
      <Route path="/kpi" element={<PrivateRoute><div>Gráficos</div></PrivateRoute>} />
      <Route path="/kpi/rendimiento" element={<PrivateRoute><SchedulePerformance /></PrivateRoute>} />
      <Route path="/kpi/seguimiento" element={<PrivateRoute></PrivateRoute>} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default RoutesApp;