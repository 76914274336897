import React from 'react';
import { Box, Paper, Typography, } from '@mui/material';

const CalificationColumn = ({ title, items, backgroundColor, textColor = '#fff' }) => (
  <Paper elevation={0}>
    <Box sx={{ bgcolor: backgroundColor, p: 1 }}>
      <Typography
        variant="subtitle1"
        align="center"
        sx={{
          color: textColor,
          fontWeight: 'bold',
        }}
      >
        {title}
      </Typography>
    </Box>
    <Box>
      {items?.map((item, index) => (
        <Typography
          key={index}
          variant="body2"
          sx={{
            p: 1,
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          {item}
        </Typography>
      ))}
    </Box>
  </Paper>
);

export default CalificationColumn;