import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { CustomLink } from '../CustomLink';

const cellStyle = {
  border: '1px solid #575756',
  padding: '10px',
  textAlign: 'right',
};

const renderRatingCells = (ratings, isLast) => (
  Object.keys(ratings).map((factor, i) => (
    <TableCell
      key={factor}
      align="right"
      style={{
        ...cellStyle,
        borderRight: isLast && i === Object.keys(ratings).length - 1 ? '1px solid #FFFFFF' : '1px solid #575756'
      }}
    >
      {ratings[factor]}
    </TableCell>
  ))
);

const EmployeeLink = ({ userId, employee }) => (
  !userId ? (
    <CustomLink
      to={`/evaluaciones/seguimiento/${employee.id}`}
      isActive={false}
      textDecoration='underline'
    >
      {employee.first_name} {employee.last_name}
    </CustomLink>
  ) : (
    `${employee.first_name} ${employee.last_name}`
  )
);

const handleStatus = (status) => {
  switch (status) {
    case 'started':
      return 'Iniciada';
    case 'pending_for_employee':
      return 'Pendiente por empleado';
    case 'pending_for_manager':
      return 'Pendingiente por líder';
    case 'completed':
      return 'Completada';
    case 'rated':
      return 'Calificada';
    default:
      return '';
  }
};

const calculatePercentage = (ratings, factorsLength) => {
  const total = Object.values(ratings).reduce((sum, rating) => sum + rating, 0);
  const maxTotal = factorsLength * 5;
  return ((total / maxTotal) * 100).toFixed(2);
};


const FactorTableRow = ({ row, index, userId, factorsLength }) => {
  const employeeAverage = calculatePercentage(row.employee_ratings, factorsLength);
  const managerAverage = calculatePercentage(row.manager_ratings, factorsLength);
  const finalConcept = ((parseFloat(employeeAverage) + parseFloat(managerAverage)) / 2).toFixed(2);


  return (
    <TableRow
      key={row.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: index % 2 === 0 ? 'primary' : '#383838' }}
    >
      <TableCell component="th" scope="row" style={cellStyle}>{row.id}</TableCell>
      <TableCell style={cellStyle}>
        <EmployeeLink userId={userId} employee={row.employee} />
      </TableCell>
      <TableCell style={cellStyle}>{row.employee.userRole.name}</TableCell>
      <TableCell style={cellStyle}>{row.evaluator.first_name} {row.evaluator.last_name}</TableCell>
      <TableCell style={cellStyle}>{row.creation_date}</TableCell>
      <TableCell style={cellStyle}>{row.employee.work_locations.name}</TableCell>
      <TableCell style={{ ...cellStyle, borderRight: '1px solid #fff' }}>{handleStatus(row.status)}</TableCell>
      <TableCell style={cellStyle}>{finalConcept}%</TableCell>
      <TableCell style={cellStyle}>{employeeAverage}%</TableCell>
      <TableCell style={{ ...cellStyle, borderRight: '1px solid #fff' }}>{managerAverage}%</TableCell>
      {renderRatingCells(row.employee_ratings, true)}
      {renderRatingCells(row.manager_ratings, false)}
    </TableRow>)

};

export default FactorTableRow;