import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: 'Calibri, "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#6dad48',
      dark: '#005837',
      contrastText: '#fff',
    },
    secondary: {
      main: '#005837',
      contrastText: '#fff',
    },
    error: {
      main: '#FF8B00',
      contrastText: '#fff',
    },
    neutral: {
      header: 'gray',
      main: '#383838',
      dark: '#575756',
      disabled: '#c4c3c3',
    },
    background: {
      default: '#383838',
      paper: '#575756',
    },
    text: {
      primary: '#ffffff',
      secondary: '#c4c3c3',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '8px 24px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 8,
          },
        },
      },
    },
  },
});