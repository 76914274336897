import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import FactorTableHeader from './FactorTableHeader';
import FactorTableRow from './FactorTableRow';

const FactorTable = ({ factors, rows, userId }) => (
  <Table sx={{ minWidth: 650 }} aria-label="simple table" >
    <FactorTableHeader factors={factors} />
    <TableBody>
      {rows.map((row, index) => (
        <FactorTableRow
          key={row.id}
          row={row}
          index={index}
          userId={userId}
          factorsLength={factors.length}
        />
      ))}
    </TableBody>
  </Table>
);

export default FactorTable;