import React from 'react';
import { Button } from '@mui/material';

export const CustomButton = ({ text, isLoading = false, ...props }) => {
  return (
    <Button
      variant="contained"
      disabled={isLoading}
      sx={{
        backgroundColor: 'primary.main',
        '&:hover': {
          backgroundColor: 'primary.dark',
        },
      }}
      {...props}
    >
      {text}
    </Button>
  );
};