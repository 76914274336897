import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import FactorTableCell from './FactorTableCell';

const cellStyle = {
  padding: '0 10px',
  margin: 0,
  textAlign: "center",
  borderLeft: '1px solid #575756',
};

const groupCellStyle = {
  borderRight: '1px solid #fff'
}

const ratedCell = {
  writingMode: "vertical-rl",
  transform: "rotate(180deg)",
  borderLeft: '1px solid #575756',

}

const FactorTableHeader = ({ factors }) => (
  <TableHead>
    <TableRow span="4" style={{ background: '#6dad48' }}>
      <TableCell style={{ ...cellStyle, ...groupCellStyle }} colSpan={7}>Datos personales</TableCell>
      <TableCell style={{ ...cellStyle, ...groupCellStyle }} colSpan={3}>Calificación</TableCell>
      <TableCell style={{ ...cellStyle, ...groupCellStyle }} colSpan={factors.length}>Autoevaluación</TableCell>
      <TableCell style={cellStyle} colSpan={factors.length}>Evaluación líder</TableCell>
    </TableRow>
    <TableRow style={{ background: '#005837' }}>
      <TableCell style={cellStyle}>
        Consecutivo
      </TableCell>
      <TableCell style={cellStyle}>
        Nombre
      </TableCell>
      <TableCell style={cellStyle}>
        Cargo
      </TableCell>
      <TableCell style={cellStyle}>
        Evaluador
      </TableCell>
      <TableCell style={cellStyle}>
        Fecha
      </TableCell>
      <TableCell style={cellStyle}>
        Lugar de trabajo
      </TableCell>
      <TableCell style={{ ...cellStyle, borderRight: '1px solid white' }}>
        Estado
      </TableCell>
      <TableCell style={ratedCell}>
        Concepto final
      </TableCell>
      <TableCell style={ratedCell}>
        Autoevaluación
      </TableCell>
      <TableCell style={{ ...ratedCell, borderRight: '1px solid white' }}>
        Concepto líder
      </TableCell>
      {factors?.map((factor, i) => (
        <FactorTableCell key={`auto-${factor.id}`} factor={factor} isLast={i === factors.length - 1} />
      ))}
      {factors?.map((factor, i) => (
        <FactorTableCell key={`lider-${factor.id}`} factor={factor} isLast={false} />
      ))}
    </TableRow>

  </TableHead>
);

export default FactorTableHeader;