import React, { useState, useEffect } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Typography
} from '@mui/material';
import userService from '../services/user';

const SupervisedUser = ({ supervisorId, selectedEmployee, handleEmployeeChange }) => {
  const [supervisedUsers, setSupervisedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { getUsersBySupervisorId } = userService();

  useEffect(() => {
    if (!supervisorId) return;

    const fetchSupervisedUsers = async () => {
      try {
        const response = await getUsersBySupervisorId(supervisorId);
        setSupervisedUsers(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchSupervisedUsers();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supervisorId]);

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error.message}</Typography>;

  return (
    supervisedUsers.length > 0 && (
      <FormControl fullWidth sx={{ mb: 4 }}>
        <InputLabel>Seleccione un empleado</InputLabel>
        <Select
          label="Seleccione un empleado"
          value={selectedEmployee}
          onChange={handleEmployeeChange}
        >
          {supervisedUsers.map((employee) => (
            <MenuItem key={employee.id} value={employee.id}>
              {employee.first_name} {employee.last_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>)
  );
};

export default SupervisedUser;