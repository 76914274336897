import axios from 'axios';
import useAuth from './auth';

const useEvaluationService = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getToken, logout } = useAuth();
  const token = getToken();

  if (!token) logout();

  const createEvaluation = async (evaluation) => {
    try {
      const response = await axios.post(`${apiUrl}/evaluation/create`, evaluation, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('There was an error creating the evaluation:', error);
      return [];
    }
  }

  const getEvaluationByUserId = async (userId) => {
    try {
      const response = await axios.get(`${apiUrl}/evaluation/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('There was an error fetching the users:', error);
      return [];
    }
  };

  const updateEvaluation = async ({ id, data }) => {
    try {
      const response = await axios.put(`${apiUrl}/evaluation/update/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
    catch (error) {
      console.error('There was an error updating the evaluation:', error);
      return [];
    }
  }

  const getFollowUp = async () => {
    try {
      const response = await axios.get(`${apiUrl}/evaluation/reports`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('There was an error fetching the users:', error);
      return [];
    }
  };

  const getFollowUpById = async (id) => {
    try {
      const response = await axios.get(`${apiUrl}/evaluation/reports/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('There was an error fetching the users:', error);
      return [];
    }
  };

  return {
    getEvaluationByUserId,
    updateEvaluation,
    createEvaluation,
    getFollowUp,
    getFollowUpById
  };
}

export default useEvaluationService;