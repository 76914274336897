import React from 'react';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const CustomInput = ({ error, helperText, type, ...props }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <TextField
      variant="outlined"
      fullWidth
      error={error}
      helperText={helperText}
      sx={{
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: 'primary.main',
          },
        },
      }}
      {...props}
      type={type === 'password' && showPassword ? 'text' : type}
      {...(type === 'password' && {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        },
      })}
    />
  );
};