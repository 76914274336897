import React from 'react';
import { Box, Typography } from '@mui/material';

export const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: 'neutral.main',
        borderTop: '1px solid',
        borderColor: 'neutral.dark',
      }}
    >
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        display={'flex'}
        textAlign={'center'}
        justifyContent={'center'}
        gap={1}
      >
        <label>©</label>
          Elite Ingenieros
        <label>{new Date().getFullYear()} - Pasión por lo que hacemos</label>
      </Typography>
    </Box>
  );
};