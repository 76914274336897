import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const navigate = useNavigate();

  const isAuthenticated = () => {
    const token = localStorage.getItem('token');

    if (!token) return false;

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      return decodedToken.exp > currentTime;
    } catch (error) {
      return false;
    }
  };

  const login = (token) => {
    localStorage.setItem('token', token);
    navigate('/');
  };

  const logout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const getToken = () => {
    return localStorage.getItem('token');
  };

  const getRole = () => {
    const token = getToken();

    if (!token) return null;

    const decodedToken = jwtDecode(token);

    return parseInt(decodedToken.role);
  }

  return {
    isAuthenticated,
    login,
    logout,
    getToken,
    getRole
  }
}

export default useAuth;