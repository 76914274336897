import React from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import LogoutIcon from '@mui/icons-material/Logout';
import useAuth from '../services/auth';
import { CustomLink } from './CustomLink';

export const Header = () => {
  const { isAuthenticated, getToken, logout } = useAuth();
  const isAuth = isAuthenticated()

  let decodedToken = null;
  if (isAuth) {
    const token = getToken();
    decodedToken = jwtDecode(token);
  }

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'background.default',
        boxShadow: 'none',
        padding: '10px'
      }}
    >
      <Toolbar sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 !important',
      }}>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <CustomLink to="/">
              <img
                src="/logo.png"
                alt="Elite Ingenieros"
                style={{ width: '10rem', objectFit: 'contain', marginLeft: '5px' }}
              />
            </CustomLink>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h6"
              component="label"
              sx={{ lineHeight: 1, fontSize: '1rem' }}
            >
              {decodedToken?.name}
            </Typography>
            {
              isAuth && (
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => logout()}
                  color="inherit"
                >
                  <LogoutIcon />
                </IconButton>
              )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};