import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import { Assessment as AssessmentIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  return (
    <Alert
      severity="info"
      icon={<AssessmentIcon fontSize="inherit" color='white' />}
      sx={{
        mb: 2,
        '& .MuiAlert-icon': {
          fontSize: '2rem',
        },
      }}
    >
      <AlertTitle sx={{ fontSize: '1.2rem', color: 'white', }}>Evaluación Disponible</AlertTitle>
      <Box container sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column' }, alignItems: { xs: 'flex-start', sm: 'center' } }}>
        <Box>
          <Typography variant="body1" component="div" sx={{ mb: 1, width: '100%', color: 'white' }}>
            La evaluación <strong>Evaluación de Desempeño</strong> está abierta hasta el próximo 13 de diciembre.
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }} >
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/evaluaciones')}
            sx={{ mt: { xs: 2, sm: 0 } }}
          >
            Ir a la evaluación
          </Button>
        </Box>
      </Box>
    </Alert>
  );
};

export default Home;