import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import CustomAccordion from './CustomAccordion';
import StyledRadio from './CustomRadio';
import { ratingScaleLikart } from '../services/constants';

const EvaluationTable = ({ rater, onChange, factors, isSuperVisor, employeeRatings, managerRatings }) => {
  const initialRatings = rater === 'manager' ? managerRatings : employeeRatings;
  const employeeLeaft = Object.keys(employeeRatings).length;
  const managerLeaft = Object.keys(managerRatings).length;
  const isDisabled = (isSuperVisor && rater === 'employee') || (!isSuperVisor && rater === 'manager')

  const handleRatingChange = (factorId, value) => onChange({ [factorId]: value });

  const handleTitle = () => {
    return rater === 'manager' ?
      `Evaluación del jefe inmediato ${managerLeaft}/${factors?.length}` :
      `Autoevaluación ${employeeLeaft}/${factors?.length}`
  }
  return (

    <CustomAccordion
      expanded={!isSuperVisor}
      title={handleTitle()}
    >
      <TableContainer component={Paper} sx={{ mb: 4, backgroundColor: 'background.paper' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>FACTORES A EVALUAR</TableCell>
              <TableCell align="center">5</TableCell>
              <TableCell align="center">4</TableCell>
              <TableCell align="center">3</TableCell>
              <TableCell align="center">2</TableCell>
              <TableCell align="center">1</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {factors?.map((factor) => (
              <TableRow key={factor.id}>
                <TableCell component="th" scope="row">
                  {factor.name}
                </TableCell>
                <TableCell colSpan={5} sx={{ padding: 0 }}>
                  <RadioGroup
                    row
                    name={`${rater}-${factor.id}`}
                    value={initialRatings[factor.id] || ''}
                    onChange={(e) => handleRatingChange(factor.id, e.target.value)}
                    sx={{ flexWrap: 'nowrap', justifyContent: 'center', padding: 0 }}
                  >
                    {ratingScaleLikart.map((rating) => (
                      <FormControlLabel
                        key={rating}
                        value={rating}
                        control={<StyledRadio />}
                        disabled={isDisabled}
                        label=""
                        sx={{ flex: 1, justifyContent: 'center', margin: 0, padding: 0 }}
                      />
                    ))}
                  </RadioGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomAccordion>
  );
};

export default EvaluationTable;