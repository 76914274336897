import axios from 'axios';
import useAuth from './auth';

const useFactorService = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getToken, logout } = useAuth();
  const token = getToken();

  if (!token) logout();

  const getFactors = async () => {
    try {
      const response = await axios.get(`${apiUrl}/factors/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('There was an error fetching the users:', error);
      return [];
    }
  };

  const getFactorsByRole = async () => {
    try {
      const response = await axios.get(`${apiUrl}/factors/role`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('There was an error fetching the users:', error);
      return [];
    }
  };

  return {
    getFactors,
    getFactorsByRole
  };
}

export default useFactorService;