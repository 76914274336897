import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton, Box, useTheme, useMediaQuery } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { CustomLink } from './CustomLink';
import useAuth from '../services/auth';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SpeedIcon from '@mui/icons-material/Speed';

export const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const { isAuthenticated, getRole } = useAuth();
  const isAuth = isAuthenticated();
  const role = getRole();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  const menuItems = [
    { text: 'Inicio', link: '/', isActive: true, icon: <HomeOutlinedIcon />, roles: [] },
    {
      text: 'Evaluación', link: '/evaluaciones', isActive: true, icon: <AssessmentIcon />, roles: [],
      subItems: [
        { text: 'Seguimiento', link: '/evaluaciones/seguimiento', isActive: true, icon: <TableViewOutlinedIcon />, roles: [] },
      ]
    },
    {
      text: 'KPI', link: '/kpi', isActive: false, icon: <TrendingUpIcon />,
      subItems: [
        { text: 'Rendimiento', link: '/kpi/rendimiento', isActive: false, icon: <SpeedIcon />, roles: [42, 51, 65, 68, 73, 11] },
        { text: 'Seguimiento', link: '/kpi/seguimiento', isActive: false, icon: <TableViewOutlinedIcon />, roles: [42, 51, 65, 68, 73, 11] },

      ],
      roles: [42, 51, 65, 68, 73, 11]
    },
  ];

  const isActive = (link) => {
    return matchPath({ path: link, end: link === '/' }, location.pathname);
  };

  if (!isAuth) return null;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {
        isSmallScreen &&
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{
            position: isSmallScreen ? 'absolute' : 'relative',
            top: 20,
            left: 1,
          }}
        >
          <MenuIcon />
        </IconButton>
      }

      <Drawer
        anchor="left"
        open={isOpen}
        variant={isSmallScreen ? "temporary" : "permanent"}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiPaper-root.MuiDrawer-paper': {
            position: isSmallScreen ? 'absolute' : 'relative',
            top: 0,
            bottom: 0,
            width: '210px',
            zIndex: '1000',
            overflow: 'hidden'
          }
        }} >
        <Box
          sx={{ width: 210, p: 2, backgroundColor: 'background.default', height: '100vh', }}
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          {
            isSmallScreen &&
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ position: 'absolute', right: 0, top: 0 }}
            >
              <CloseIcon />
            </IconButton>
          }

          <List>
            {menuItems.map((item, index) => (
              <Box key={item.text} sx={{ marginBottom: '0.5rem' }}>
                {
                  item.isActive &&
                  (item.roles.length === 0 || item.roles.includes(role)) &&
                  <>
                    <ListItem isActive={isActive(item.link)} component={CustomLink} to={item.link}>
                      {item.icon}
                      <ListItemText primary={item.text} />
                    </ListItem>

                    {item?.subItems?.map(subItem => (
                      subItem.isActive &&
                      (subItem.roles.length === 0 || subItem.roles.includes(role)) &&
                      <Box key={subItem.text} container sx={{ ml: '1rem' }}>
                        <ListItem isActive={isActive(subItem.link)} key={subItem.text} component={CustomLink} to={subItem.link}>
                          {subItem.icon}
                          <ListItemText primary={subItem.text} />
                        </ListItem>
                      </Box>
                    ))}
                  </>
                }

              </Box>
            ))}
          </List>
        </Box>
      </Drawer >
    </Box >
  );
};