import React from 'react';
import TableCell from '@mui/material/TableCell';

const FactorTableCell = ({ factor, isLast }) => (
  <TableCell
    align="center"
    key={factor.id}
    style={{
      transform: "rotate(-180deg)",
      padding: '10px 0',
      margin: 0,
      writingMode: "vertical-rl",
      textAlign: "left",
      borderLeft: '1px solid',
      borderColor: '#575756',
      borderRight: isLast ? '1px solid #FFFFFF' : '1px solid #575756'
    }}
  >
    {factor.name}
  </TableCell>
);

export default FactorTableCell;