import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Container, TableContainer, Paper } from '@mui/material';
import useFactorService from '../services/factor';
import useEvaluationService from '../services/evaluation';
import { useParams } from 'react-router-dom';
import FactorTable from '../components/CustomTable/FactorTable';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CustomLink } from '../components/CustomLink';

export const FollowUp = () => {
  const [factors, setFactors] = useState([]);
  const [rows, setRows] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const { userId } = useParams();
  const containerRef = useRef(null);

  // services
  const { getFactors } = useFactorService();
  const { getFollowUp, getFollowUpById } = useEvaluationService();

  // functions
  const fetchFactors = async () => {
    const factors = await getFactors();

    setFactors(factors);
  };


  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    setStartX(e.clientX);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isMouseDown) return;

    const x = e.clientX;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => setIsMouseDown(false);

  const handleMouseLeave = () => setIsMouseDown(false);

  const fetchEvaluations = async () => {
    let rows = [];

    if (userId) {
      rows = await getFollowUpById(userId);

      if (rows.length === 0) {
        setEmployee('');
        return;
      }

      setEmployee(rows[0]?.employee.first_name + " " + rows[0]?.employee.last_name);
    } else {
      rows = await getFollowUp();
      setEmployee(null);
    }

    const initializeRatings = (ratings) => {
      const initializedRatings = {};

      factors.forEach(factor => {
        initializedRatings[factor.id] = parseInt(ratings[factor.id]) || 0;
      });

      return initializedRatings;
    };

    const processedRows = rows?.map(row => ({
      ...row,
      employee_ratings: initializeRatings(row.employee_ratings),
      manager_ratings: initializeRatings(row.manager_ratings),
    }));

    setRows(processedRows);
  }

  useEffect(() => {
    fetchFactors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchEvaluations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factors, userId]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      bgcolor: 'background.default',
      color: 'text.primary'
    }}>
      <Container maxWidth="xl" sx={{ flexGrow: 1, py: 4 }}>
        <Typography variant="h4" marginBottom={0} color="primary">
          Resumen de evaluaciones de desempeño
        </Typography>

        {employee &&
          <Typography variant='h6' marginBottom={'1rem'} >{employee}</Typography>
        }

        <br />

        {
          userId &&
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <CustomLink to="/evaluaciones/seguimiento" isActive={false} textDecoration='underline' sx={{ mb: 2 }}>
              <ChevronLeftIcon /> Regresar
            </CustomLink>
          </Box>
        }

        {rows.length === 0 ?
          <Typography variant="h6" marginBottom={0} marginTop={2} color="primary">
            No hay evaluaciones de desempeño para mostrar
          </Typography>
          :
          <TableContainer
            ref={containerRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            component={Paper}
            sx={{ cursor: isMouseDown ? 'grabbing' : 'grab', userSelect: 'none' }}
          >
            <FactorTable factors={factors} rows={rows} userId={userId} />
          </TableContainer>
        }
      </Container >

    </Box >
  );
}

export default FollowUp;