import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import CalificationColumn from './CalificationColumn';

const EvaluationColumns = ({ evaluation, factors, isSuperVisor }) => {
  const [aspectsToImprove, setAspectsToImprove] = useState([]);
  const [strengths, setStrengths] = useState([]);
  const [competentAreas, setCompetentAreas] = useState([]);
  const [calculation, setCalculation] = useState(0);

  useEffect(() => {
    const { manager_ratings } = evaluation[0];

    const auxStrengths = [];
    const auxCompetentAreas = [];
    const auxAspectsToImprove = [];
    let auxCalculation = 0;

    factors?.forEach(factor => {
      const value = parseInt(manager_ratings[factor.id]);
      auxCalculation += value;

      if (value >= 4) {
        auxStrengths.push(factor.name)
      } else if (value === 3) {
        auxCompetentAreas.push(factor.name)
      } else {
        auxAspectsToImprove.push(factor.name)
      }

      setStrengths(auxStrengths);
      setCompetentAreas(auxCompetentAreas);
      setAspectsToImprove(auxAspectsToImprove);
    });

    setCalculation((auxCalculation * 100) / (factors?.length * 5));
  }, [evaluation, factors]);

  return (
    <>
      <Stack
        direction={{ xs: 'column', }}
        spacing={1}
        sx={{
          width: '100%',
          minHeight: 400,
          marginBottom: 4
        }}
      >
        <Typography variant="h4" gutterBottom color="primary">
          Calificación: {calculation.toFixed(2)}%
        </Typography>

        <Typography variant="h6" gutterBottom color="white">
         {calculation.toFixed(2) <= 70 ? 'Plan de seguimiento' : '¡Pasión por lo que hacemos!'}
        </Typography>
        <CalificationColumn
          title="ASPECTOS A MEJORAR"
          items={aspectsToImprove}
          backgroundColor="#f44336"
        />
        <CalificationColumn
          title="FORTALEZAS"
          items={strengths}
          backgroundColor="#4caf50"
        />
        <CalificationColumn
          title="ÁREAS COMPETENTES"
          items={competentAreas}
          backgroundColor={'error.dark'}
        />
      </Stack>

      <Stack
        direction={{ xs: 'column', juscifyContent: 'space-between', mb: 2 }}
        spacing={1}
        sx={{
          width: '100%',
          minHeight: 400,
          marginBottom: 4
        }}
      >
        {
          isSuperVisor &&
          <>
            <Typography variant="h6" gutterBottom color="primary">
              Plan de mejoramiento
            </Typography>
            <CalificationColumn
              title="ACCIONES RECOMENDADAS POR EL EVALUADOR"
              items={evaluation[0]?.evaluator_actions}
              backgroundColor="neutral.disabled"
            />
            <CalificationColumn
              title="COMPROMISOS DEL COLABORADOR"
              items={evaluation[0]?.worker_commitments}
              backgroundColor="neutral.disabled"
            />
          </>
        }
      </Stack>
    </>
  );
};

export default EvaluationColumns;