import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../services/auth';
import { Box } from '@mui/material';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const isAuth = isAuthenticated();

  return isAuth ?
    <Box sx={{ padding: 2 }} > {children} </Box>
    :
    <Navigate to="/login" />;
};

export default PrivateRoute;