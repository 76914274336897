import React from 'react';
import CustomAccordion from './CustomAccordion';
import DynamicList from './DynamicList';

const ImprovementPlan = ({
  evaluatorActions,
  workerCommitments,
  setEvaluatorActions,
  setWorkerCommitments,
}) => {
  const addEvaluatorAction = (action) => {
    setEvaluatorActions([...evaluatorActions, action]);
  };

  const deleteEvaluatorAction = (index) => {
    const newEvaluatorActions = [...evaluatorActions];
    newEvaluatorActions.splice(index, 1);
    setEvaluatorActions(newEvaluatorActions);
  };

  const addWorkerCommitment = (commitment) => {
    setWorkerCommitments([...workerCommitments, commitment]);
  };

  const deleteWorkerCommitment = (index) => {
    const newWorkerCommitment = [...workerCommitments];
    newWorkerCommitment.splice(index, 1);
    setWorkerCommitments(newWorkerCommitment);
  };

  return (
    <CustomAccordion
      title={'Plan de mejora'}
    >
      <DynamicList
        title="Acciones recomendadas por el evaluador"
        color="primary.main"
        items={evaluatorActions}
        onAddItem={addEvaluatorAction}
        onDeleteItem={deleteEvaluatorAction}
      />
      <DynamicList
        title="Compromisos del colaborador"
        color="primary.main"
        items={workerCommitments}
        onAddItem={addWorkerCommitment}
        onDeleteItem={deleteWorkerCommitment}
      />
    </CustomAccordion>
  );
}

export default ImprovementPlan;